import Loader from "../../../assets/svgs/loader";
import { ISearchDedicationInput } from "../../../types";

interface SearchViewProps {
  result: ISearchDedicationInput[];
  value: string;
  onChange: (q: string) => void;
  action: (data: ISearchDedicationInput) => void;
  isLoading: boolean
}

const SearchView: React.FC<SearchViewProps> = ({ result, value, onChange, action, isLoading }) => {

  return (
    <div className=" search-box-container">
      <div className="search-box-content">
        <h1 className=" search-title">The Kiss NFT</h1>
        <p className="search-description">Search for your name to find out who dedicated their token to you.</p>
        <div className="search-input-container">
          <input type="text" placeholder="Search your name" className="search-input" defaultValue={value} onChange={(e) => onChange(e.target.value)} />
          {isLoading && <Loader />}
        </div>
        {result.length ?
          <div className="search-result-list">
            {result.map((item, idx) => (
              <div className=" search-result-container" key={idx} onClick={() => action(item)}>
                <p className=" search-result">{`${item.first_name}  ${item.last_name}`}</p>
              </div>
            ))}
          </div>
          : null}
        {/* <div className=" search-btn-container">
            <button className="search-btn">
              Search
            </button>
          </div> */}
      </div>
    </div>);
}

export default SearchView;