import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const GustavIcon: React.FC<Props> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="115.585" height="55.906" viewBox="0 0 115.585 55.906">
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectangle_35" data-name="Rectangle 35" width="115.585" height="55.906" fill="#d2c090" />
        </clipPath>
      </defs>
      <g id="Group_283" data-name="Group 283" transform="translate(0.001 -23)" style={{ mixBlendMode: "normal", isolation: "isolate" }}>
        <g id="Group_10" data-name="Group 10" transform="translate(-0.001 23)" clipPath="url(#clip-path)">
          <path id="Path_9" data-name="Path 9" d="M205.63,113.6a7.379,7.379,0,0,1,.339,1.068c.186,1.142.959,2.13.863,3.343-.012.147-.023.392.051.432.846.456.417,1.365.739,2,.251.494.36,1-.184,1.328a1.294,1.294,0,0,1-1.681-.025,15.761,15.761,0,0,1-1.383-1.924c-.162-.236-.247-.675-.435-.718-1.113-.249-1.231-1.215-1.477-2.021-.364-1.192-.607-2.424-.863-3.647a5.065,5.065,0,0,0-1.277-2.966,4.236,4.236,0,0,1-1.185-2.842,22.177,22.177,0,0,0-.9-3.8.411.411,0,0,0-.3-.252.525.525,0,0,0-.339.258c-.724,1.3-1.474,2.586-2.125,3.921a4.689,4.689,0,0,0-.276,1.58,4.381,4.381,0,0,1-2.525,3.649,1.769,1.769,0,0,1-2.7-1.01,3.405,3.405,0,0,0-1.185-1.679,1.068,1.068,0,0,1-.363-.471c-.743-1.8-1.462-3.618-2.211-5.419a6.688,6.688,0,0,0-.818-1.581c-.508-.646-1.04-.6-1.45.075a4.15,4.15,0,0,0-.655,1.511,9.029,9.029,0,0,1-2.114,4.385c-.121.156-.329.34-.313.491.116,1.08-.646,1.745-1.138,2.538-.106.171-.284.33-.31.512-.226,1.585-1.42,2.874-1.384,4.536.033,1.531-1.177,2.349-1.965,3.407-.055.074-.176.166-.241.149-.13-.034-.353-.151-.345-.21.127-.987-1.55-.166-1.343-1.491.286-1.827,1.265-3.373,1.684-5.123a5.79,5.79,0,0,1,1.536-3.162,2.767,2.767,0,0,0,.643-1.5,2.315,2.315,0,0,1,1.087-1.824c.5-.315.655-1.172.969-1.783.708-1.378,1.454-2.739,2.114-4.139.384-.813.628-1.692.945-2.538a2.235,2.235,0,0,1,3.268-1.053,3.005,3.005,0,0,1,1.695,2.8.849.849,0,0,0,.151.572c.975.991,1.066,2.417,1.775,3.54.426.675.725,1.427,1.12,2.123a7.706,7.706,0,0,0,.7.9,5.334,5.334,0,0,0,.915-.871c.41-.629.733-1.316,1.082-1.984.552-1.056,1.022-2.162,1.662-3.16.617-.962,1.447-1.784,2.1-2.724a1.739,1.739,0,0,1,3.146.466c.107.592.22,1.184.359,1.769.171.718.2,1.488.9,2,.211.156.28.52.387.8q1.591,4.126,3.166,8.259c.173.457.262.945.39,1.419l-.287.087" transform="translate(-121.329 -67.778)" fill="#d2c090" />
          <path id="Path_10" data-name="Path 10" d="M10.825,98c-.178,2.55-.416,5.1-.508,7.65a31.043,31.043,0,0,0,.286,3.73c.04.47.31.5.594.235,1.371-1.3,2.724-2.619,4.081-3.933.214-.208.4-.452.629-.636,1.194-.95,2.585-1.706,2.794-3.486a2.264,2.264,0,0,1,.828-1.233,16.82,16.82,0,0,0,2.988-3.866,40.385,40.385,0,0,1,2.7-3.393c.253-.31.579-.566.8-.892.619-.894.61-.91,1.527-.276a3.035,3.035,0,0,1,.922.758,8.519,8.519,0,0,1,.461,1.376c.271.706-.387.671-.693.93-.228.193-.551.4-.607.651-.482,2.169-1.966,3.724-3.239,5.411-1.223,1.62-1.181,2.364.112,4,1.367,1.733,2.659,3.525,4.052,5.237q1.021,1.255,2.043,2.508c1.035,1.267,1.226,2.839,1.835,4.254.2.46-.222.686-.606.457a14.971,14.971,0,0,1-2.585-1.753,14.014,14.014,0,0,1-2.211-2.658,20.258,20.258,0,0,0-4.42-4.881,15.489,15.489,0,0,1-1.7-1.91c-.747-.84-1.182-.8-1.649.2-.19.408-.225.856-.9.639-.128-.041-.443.49-.668.761-.928,1.114-1.861,2.224-2.774,3.349a5.614,5.614,0,0,0-.417.758c-.12.207-.28.591-.38.578-.824-.108-1.174.654-1.79.919a2.718,2.718,0,0,0-1.659,1.835,2.6,2.6,0,0,1-.177.416,1.63,1.63,0,0,1-1.418.982c-.461.041-1.184-.674-1.374-1.262-.367-1.136-.75-2.267-1.093-3.409a3.177,3.177,0,0,1-.113-1.037c.074-1.652.174-3.3.265-4.955.1-1.9.215-3.8.3-5.707a2.894,2.894,0,0,0-.086-1.194c-.573-1.387-.219-2.759-.053-4.143.084-.7.112-1.4.159-2.1.024-.351-.082-.768.075-1.039a2.414,2.414,0,0,1,1.006-1c.184-.081.7.334.91.631a7.89,7.89,0,0,1,.742,1.475,7.54,7.54,0,0,0,.345.994A5.82,5.82,0,0,1,10.825,98" transform="translate(-4.533 -63.325)" fill="#d2c090" />
          <path id="Path_11" data-name="Path 11" d="M0,19.269a6.2,6.2,0,0,1,1.439-4.057c.992-1.249,1.633-2.774,2.615-4.033A41.423,41.423,0,0,1,7.97,6.844C8.784,6.06,9.9,5.6,10.8,4.892A12.136,12.136,0,0,1,13.43,2.946c.275-.116.517-.316.8-.41.771-.258.96-.081.865.754a2.768,2.768,0,0,0-.071.745c.209,1.219.043,2.2-1.358,2.595-.187.052-.283.383-.451.556a3.888,3.888,0,0,1-1.049.977,3.239,3.239,0,0,0-2.01,2.247,1.776,1.776,0,0,1-.5.889,13.967,13.967,0,0,1-2.439,1.724,3.013,3.013,0,0,0-1.28,1.193C4.91,15.869,3.954,17.574,4.138,19.6a4.448,4.448,0,0,0,2.2,3.818c1.532.917,3.135.87,4.722,1.182.44.087.9.056,1.347.128,1.193.192,1.817-.224,1.936-1.394a20.955,20.955,0,0,0,.183-2.995,12.686,12.686,0,0,1,.511-5.1,5.157,5.157,0,0,0,.211-.876c.2-1.11.353-1.237,1.379-1.429a6.96,6.96,0,0,0,1.208-.444c.2-.078.393-.173.589-.26a2.493,2.493,0,0,1,.272.9,9.5,9.5,0,0,1-.388,1.271,20.008,20.008,0,0,0-.006,7.444c.184,1.51.323,2.969-1.043,4.118a1.12,1.12,0,0,0-.3,1.572c.411.719-.012,1.247-.856,1.3a3.657,3.657,0,0,1-1.043-.1,7.57,7.57,0,0,0-4.572.124,2.247,2.247,0,0,1-2.626-.426c-.295-.292-.522-.379-.884-.082-.115.094-.439-.032-.654-.1-.789-.246-1.388-1.036-2.358-.8-.113.028-.324-.2-.44-.353A19.243,19.243,0,0,1,.51,21.9,16.556,16.556,0,0,1,0,19.269" transform="translate(0 -1.691)" fill="#d2c090" />
          <path id="Path_12" data-name="Path 12" d="M267.133,27.753c-.056,1.048-.141,2.095-.16,3.143a3.355,3.355,0,0,0,.889,2.46,9.824,9.824,0,0,1,1.331,2.5c.069.15-.081.441-.193.629-.35.587-1.12.879-1.191,1.686-.005.058-.481.187-.576.1a3.671,3.671,0,0,1-.74-.933,18.849,18.849,0,0,1-.928-1.9c-.255-.611-.38-1.228-1.326-1.128-.23.024-.639-.68-.793-1.11a15.671,15.671,0,0,0-1.8-3.359c-1.083-1.183-2.089-2.447-3.253-3.544a19.6,19.6,0,0,1-2.476-2.949.8.8,0,0,0-.618-.323c-.153.025-.282.35-.371.564-.17.4-.186.962-.476,1.211a7.441,7.441,0,0,0-1.819,3.078c-.251.6-.56,1.177-.812,1.777a4.32,4.32,0,0,0-.44,2.905,1.784,1.784,0,0,1-1.266,1.95c-.142.035-.359.085-.436.014a8.884,8.884,0,0,1-1.337-1.384c-.144-.209.024-.656.092-.985.774-3.732,2.7-7.013,4.135-10.483.594-1.433,1.077-2.917,1.755-4.308.466-.955.585-2.145,1.641-2.77a.69.69,0,0,0,.33-.464,3.95,3.95,0,0,1,1.521-2.682c.756-.8,1.573-1.551,2.406-2.274.479-.415.806-.3.969.33a2.281,2.281,0,0,1-.028,1.855,2.709,2.709,0,0,0,.264,2.857c.715.832.5,1.906,1.17,2.705a17,17,0,0,1,1.459,2.435,4.72,4.72,0,0,1,.5.921,35.454,35.454,0,0,0,2.32,6.029,7.492,7.492,0,0,1,.419,1.438l-.168.005m-3.457-1.428a11.809,11.809,0,0,0-.583-2.034c-1.2-2.526-2.47-5.021-3.725-7.521-.192-.382-.445-.734-.669-1.1a6.408,6.408,0,0,0-1.081,1,2.015,2.015,0,0,0-.364,1.216,1.694,1.694,0,0,1-.449,1.36c-.457.542-.39.985.208,1.5a7.925,7.925,0,0,1,2.257,2.322c.161.327.592.517.888.782.987.885,2.278,1.448,2.888,2.738.086.183.3.3.458.454l.173-.721" transform="translate(-173.021 -6.225)" fill="#d2c090" />
          <path id="Path_13" data-name="Path 13" d="M65.749,3.228A23.486,23.486,0,0,1,66.366.676a1.141,1.141,0,0,1,.857-.637c.293.01.611.4.844.687.145.177.077.559.235.688.414.337,1.124.49,1.3.894s-.169,1.033-.252,1.568c-.02.13.087.283.144.422,1.409,3.428,2.811,6.859,4.24,10.279.19.455.545.839.772,1.282a14.2,14.2,0,0,1,1.186,2.552c.182.654.617.646,1,.625.211-.011.457-.413.588-.687.994-2.069,1.926-4.168,2.951-6.221s2.2-4.029,3.164-6.107A3.537,3.537,0,0,0,83.54,3.7c-.418-1.379.617-1.961,1.224-2.79C84.9.733,85.23.7,85.37.52c.165-.21.126-.686.564-.459a1.17,1.17,0,0,1,.523.661c.171.611-.173,1.5.931,1.545.035,0,.1.079.1.1-.32,1.051.635,1.92.466,2.882a4.137,4.137,0,0,1-1.008,1.816A6.863,6.863,0,0,0,85,10.343a.639.639,0,0,1-.2.394c-1.645,1.14-1.835,3.037-2.484,4.7-.25.641-.612,1.239-.872,1.877-.689,1.69-2.052,3.05-2.4,4.912a1.693,1.693,0,0,1-.45.773,2.592,2.592,0,0,0-.755,2.17c.085.638-.335.765-.748.946a.793.793,0,0,1-1.063-.284,30.14,30.14,0,0,1-3.359-5.467A13.815,13.815,0,0,0,70.6,16.224a2.024,2.024,0,0,1-.333-.959,3.652,3.652,0,0,0-1.518-2.5A1.786,1.786,0,0,1,68,11.376,16.2,16.2,0,0,0,66.611,6.96a18.294,18.294,0,0,1-.862-3.731" transform="translate(-45.817 0)" fill="#d2c090" />
          <path id="Path_14" data-name="Path 14" d="M202.665,17.94c-.278,2.518-.721,5.032-.778,7.555a37.119,37.119,0,0,1-.4,5.469,10.266,10.266,0,0,0-.172,3.287c.16.993-1,1.371-.75,2.274-.118.022-.272.1-.347.054a.956.956,0,0,1-.423-.415c-.479-1.514-.951-3.031-1.376-4.561a7.742,7.742,0,0,1-.256-1.752c-.015-.364.26-.76.191-1.1-.137-.666.658-1.649-.611-2a1.384,1.384,0,0,1,.125-.927,1.756,1.756,0,0,0,.339-2.383.956.956,0,0,1-.077-.584,27.239,27.239,0,0,0,.311-6.69,6.124,6.124,0,0,1,.2-1.029c.222-1.388-.306-2.2-1.679-2.3a6.278,6.278,0,0,1-3.163-.648c-.113-.074-.3-.039-.445-.057a5.147,5.147,0,0,1-2.321-1.233c-.17-.166-.484-.188-.646-.358-.221-.23-.562-.622-.491-.8.132-.319.514-.73.816-.751a41.119,41.119,0,0,1,4.791-.136c2.53.117,5.054.372,7.581.571,1.089.085,2.178.183,3.268.259a2.54,2.54,0,0,0,1.047-.025c1.419-.558,2.8,0,4.171.16a8.294,8.294,0,0,1,2.408,1.115.408.408,0,0,1,.2.217c.192.688-.806,1.275-.173,2.007-.457.173-1,.6-1.358.477-1.718-.559-3.279.223-4.89.45a4.848,4.848,0,0,1-2.208-.076,2.114,2.114,0,0,0-2.824,1.961c-.043.65-.007,1.3-.007,1.957l-.047.005" transform="translate(-132.319 -6.157)" fill="#d2c090" />
          <path id="Path_15" data-name="Path 15" d="M309.141,15.568c.336.11.521.114.613.209a6.132,6.132,0,0,1,2.092,3.259c.17,1.135.367,2.268.612,3.388.185.845.471,1.667.7,2.5.238.87.432,1.753.7,2.612a14.6,14.6,0,0,0,.7,1.664,27.986,27.986,0,0,1,2.259,5.673c.063.292.345.536.526.8.236-.248.559-.456.691-.751.348-.777.6-1.595.916-2.388a7.864,7.864,0,0,1,.731-1.645,65.154,65.154,0,0,0,4.9-8.945,27.033,27.033,0,0,1,1.982-3.523,2.128,2.128,0,0,1,1.523-.7c.535-.015.766.837.724,1.574a5.806,5.806,0,0,1-1.176,2.787,43.907,43.907,0,0,0-1.835,4.123c-1.1,2.258-2.328,4.452-3.4,6.723a18.835,18.835,0,0,0-1.837,3.964,21.517,21.517,0,0,1-1.331,3.16,1.744,1.744,0,0,1-1.377,1.208.642.642,0,0,0-.144.046l-1.759.778a9.612,9.612,0,0,0-.473-1.783,22.793,22.793,0,0,0-1.258-2.034,3.485,3.485,0,0,1-.63-1.182,13.226,13.226,0,0,0-1.179-3.482c-.247-.567-.482-1.136-.674-1.724-.282-.862-1.491-4.1-1.611-4.558-.424-1.642-.821-3.3-1.2-4.952a33.428,33.428,0,0,1-.712-3.474,8.21,8.21,0,0,1,.129-2.5c.066-.339.558-.6.8-.834" transform="translate(-214.748 -10.849)" fill="#d2c090" />
          <path id="Path_16" data-name="Path 16" d="M303.766,129.641c.272-3.03.556-6.058.8-9.09a3.16,3.16,0,0,0-.214-1.261,1.85,1.85,0,0,1,.237-2.081c.836-.972.412-2.187.36-3.309-.005-.114-.294-.3-.452-.305-.5,0-1.136-.1-1.457.156-.611.5-1.2.106-1.716.215-1.829.383-3.365-.71-5.083-.742-1.224-.023-1.853-.892-2.354-1.853-.069-.132.075-.42.188-.592.346-.523,1.168-.881.544-1.71.175-.108.412-.352.516-.3,2.143.977,4.419.85,6.675.8a9.735,9.735,0,0,0,2.671-.387,6.6,6.6,0,0,1,4.423.114,13.176,13.176,0,0,0,4.989.7,7.532,7.532,0,0,1,4.133,1.254,9.785,9.785,0,0,0,1.249.841c1.077.456.929,1.448,1.091,2.3.027.142-.138.378-.279.483a2.221,2.221,0,0,1-2.5-.095,3.024,3.024,0,0,0-1.924-.662c-1.7-.1-3.388-.334-5.081-.511-.249-.026-.5-.055-.75-.058-1.465-.016-1.608.042-1.868,1.522a27.323,27.323,0,0,0-.19,2.962c-.091,1.4-.178,2.8-.295,4.2a22.233,22.233,0,0,0-.083,5.819,3.682,3.682,0,0,1-.707,2.708c-.591.851-1.076,1.086-1.863.859-.673-.195-.955-.667-.995-1.664,0-.1,0-.2,0-.3l-.063-.006" transform="translate(-204.783 -75.788)" fill="#d2c090" />
          <path id="Path_17" data-name="Path 17" d="M94.27,112.441a3.193,3.193,0,0,1,.062-.6c.914-2.557.342-5.3.972-7.889a.84.84,0,0,0-.022-.578c-.734-1.149-.161-2.241.178-3.3a4.318,4.318,0,0,0,.157-2.047,15.7,15.7,0,0,1-.168-5.817c.124-.545.253-.9.954-.889.673.015,1.3-.421,2.045-.159.455.16.658.343.688.821A17.246,17.246,0,0,1,99.35,93.9c-.031.609-.349,1.2-.416,1.812-.18,1.648-.776,3.3.045,4.956a1.783,1.783,0,0,1,.069.881q-.276,3.539-.583,7.075c-.017.2-.1.389-.136.586-.1.609-.075.569.268.785.38.24.663.68,1.064.83a28.678,28.678,0,0,0,3.449,1.072c.552.13,1.372-.25,1.707.032a1.511,1.511,0,0,0,1.48.383c1.4-.416,2.888-.1,4.293-.721a3.739,3.739,0,0,1,2.309.345.84.84,0,0,1,.041,1.488,17.978,17.978,0,0,1-2.356,1.331c-1.123.544-2.3.99-3.416,1.541-.811.4-1.167.479-1.705-.208a1.782,1.782,0,0,0-2.076-.739,4.127,4.127,0,0,1-1.921-.234,9.706,9.706,0,0,0-5.111.075.917.917,0,0,1-1.28-.634c-.3-.742-.57-1.5-.8-2.114" transform="translate(-65.692 -63.461)" fill="#d2c090" />
          <path id="Path_18" data-name="Path 18" d="M136.819,17.588c-.448-.932-.278-2.106-1.533-2.492a.983.983,0,0,1-.433-.8c.111-2.385,1.316-4.386,2.352-6.442a1.225,1.225,0,0,1,.317-.314c1.019-.888,2.031-1.785,3.066-2.654a3.476,3.476,0,0,1,1.237-.817c.421-.1,1.167-.04,1.335.226a1.336,1.336,0,0,1-.012,1.672,1.756,1.756,0,0,1-.327.312,11.909,11.909,0,0,0-2.9,3.353,3.692,3.692,0,0,0-.457,3.551,9.812,9.812,0,0,1-.314,7.892,2.526,2.526,0,0,0-.258.544c-.573,2.775-2.724,4.307-4.643,6.067a17.592,17.592,0,0,0-2.121,2.866,1.965,1.965,0,0,0-.168.39c-.742-.788-1.721.816-2.463-.238-.063-.089-.415-.062-.578.019-.674.337-1.342.692-1.982,1.089a1.007,1.007,0,0,1-1.385-1.423,7.774,7.774,0,0,1,3.012-2.871,12.205,12.205,0,0,0,4.853-4.113c.089-.121.129-.313.244-.374,1.415-.753,1.753-2.284,2.5-3.5a8.66,8.66,0,0,0,.651-1.942" transform="translate(-87.339 -2.805)" fill="#d2c090" />
          <path id="Path_19" data-name="Path 19" d="M161.081,110.513c.219-1.833.395-3.63.672-5.41a2.011,2.011,0,0,0-.15-1.268c-.382-.841-.1-1.338.674-1.861a3.9,3.9,0,0,1,2.751-.513c.775.086.9.539.514,1.25s-.963,1.439-.405,2.335c-.574,1.09.092,2.244-.166,3.334a6.1,6.1,0,0,0,0,2.52,9.545,9.545,0,0,1-.024,2.931c-.058.4-.608.719-.9,1.1a7.806,7.806,0,0,0-.5.852,6.347,6.347,0,0,1-1.372-.849,2.766,2.766,0,0,1-.676-1.327c-.206-1.031-.29-2.086-.42-3.095" transform="translate(-112.25 -70.671)" fill="#d2c090" />
          <path id="Path_20" data-name="Path 20" d="M353.643,151.381a6.507,6.507,0,0,1,.537-1.525,1.207,1.207,0,0,1,1.028-.389,10.572,10.572,0,0,1,2.282.714c.731.319.886.963.516,1.773-.206.451-.521.855-.7,1.313-.636,1.6-.623,1.6-2.153.681a2.86,2.86,0,0,1-1.507-2.567" transform="translate(-246.438 -104.149)" fill="#d2c090" />
          <path id="Path_21" data-name="Path 21" d="M167.751,75.418a6.542,6.542,0,0,1,1.593.641c.872.653.892,1.4.268,2.923a1.726,1.726,0,0,1-1.963,1,.944.944,0,0,1-.422-.12,5.006,5.006,0,0,1-1.138-3.383c.231-.71.586-.911,1.662-1.066" transform="translate(-115.712 -52.555)" fill="#d2c090" />
        </g>
      </g>
    </svg>
  );
};

export default GustavIcon;
