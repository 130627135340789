import ArteqIcon from "../../assets/svgs/arteq";
import BelvedereIcon from "../../assets/svgs/belvedere";
import useSearch from "../../hooks/useSearch";
import ImageView from "../modules/search/imageView";
import NftList from "../modules/search/nftList";
import RandomDedication from "../modules/search/randomDedication";
import RandomDedicationSkelton from "../modules/search/randomDedicationSkelton";
import SearchView from "../modules/search/searchView";

interface SearchProps {

}

const Search: React.FC<SearchProps> = () => {
  const { view, searchResult, handleChange, fetchOwner, searchValue, tokens, selectedToken, setSelectedToken, detailView, tokenDetail, isLoading, randomDedication, randomDedicationDetail, onBack, singleDedication } = useSearch()
  // useEffect(() => {
  //   let timer = setTimeout(() => {
  //     window.location.href = 'https://thekiss.art/search-intro';
  //   }, 60000); // 60 seconds

  //   const resetTimer = () => {
  //     clearTimeout(timer);
  //     timer = setTimeout(() => {
  //       window.location.href = 'https://thekiss.art/search-intro';
  //     }, 60000);
  //   };

  //   window.addEventListener('mousemove', resetTimer);
  //   window.addEventListener('mousedown', resetTimer);
  //   window.addEventListener('keypress', resetTimer);
  //   window.addEventListener('scroll', resetTimer);

  //   return () => {
  //     clearTimeout(timer);
  //     window.removeEventListener('mousemove', resetTimer);
  //     window.removeEventListener('mousedown', resetTimer);
  //     window.removeEventListener('keypress', resetTimer);
  //     window.removeEventListener('scroll', resetTimer);
  //   };
  // }, []);
  return (
    <div className="search-default-container">
      <div className="logo-container">
        <BelvedereIcon style={{ marginBottom: 4 }} />
        <ArteqIcon />
      </div>
      {detailView === "dedication" ? randomDedication ? <RandomDedication data={randomDedication} detail={randomDedicationDetail} /> : <RandomDedicationSkelton /> : null}
      {detailView === "nft" ? <ImageView tokenDetail={tokenDetail} isLoading={isLoading} dedication={singleDedication} /> : null}
      {view === "search" ?
        <SearchView
          result={searchResult}
          value={searchValue}
          onChange={handleChange}
          action={fetchOwner}
          isLoading={isLoading}
        />
        : null}
      {view === "list" ? <NftList
        tokens={tokens}
        selectedToken={selectedToken}
        onSelect={setSelectedToken}
        isLoading={isLoading}
        onBack={onBack}
        tokenInfo={tokenDetail}
      /> : null}
    </div>
  );
}

export default Search;