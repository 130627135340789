import { useEffect, useState } from "react";
import { useDebounce } from "./useDebounce";
import { IDedicationDetail, INftsInput, IRandomDedication, ISearchDedicationInput, ISingleDedication, ISingleDedicationInput, ITokenDetailInput, ITokenInfoInput } from "../types";
import SearchApi from "../services/api/search";


function useSearch() {
  const [searchValue, setSearchValue] = useState("")
  const [randomDedication, setRandomDedication] = useState<IRandomDedication | undefined>(undefined)
  const [singleDedication, setSingleDedication] = useState<ISingleDedication | undefined>(undefined)
  const [randomDedicationDetail, setRandomDedicationDetail] = useState<IDedicationDetail | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [searchResult, setSearchResult] = useState<ISearchDedicationInput[]>([])
  const [tokens, setTokens] = useState<number[]>([])
  const [view, setView] = useState<"search" | "list">("search")
  const [detailView, setDetailView] = useState<"dedication" | "nft">("dedication")
  const [tokenDetail, setTokenDetail] = useState<ITokenInfoInput | undefined>(undefined)
  // const [randomTokenDetail, setRandomTokenDetail] = useState<ITokenInfoInput | undefined>(undefined)
  const [selectedToken, setSelectedToken] = useState<undefined | number>(undefined)
  const debouncedValue = useDebounce<string>(searchValue, 500)
  const { SKEPTIC_CONNECT_CHAIN, SKEPTIC_CONNECT_NETWORK, SKEPTIC_CONNECT_SERVICES_BASE_URL, artDropContract } = window

  useEffect(() => {
    getRandomDedication()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (searchValue) {
      fetchSearchData()
    } else {
      setSearchResult([])
    }
    // eslint-disable-next-line
  }, [debouncedValue])

  useEffect(() => {
    if (selectedToken) {
      getTokenInfo(selectedToken)
    }
    // eslint-disable-next-line
  }, [selectedToken])


  const getRandomDedication = async () => {
    SearchApi.getRandomDedication().then(res => {
      console.log(res)
      if (res.results?.[0]) {
        setRandomDedication(res.results[0])
        singleTokenOrdinal(res.results[0].token_id)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const handleChange = (q: string) => {
    setSearchValue(q)
  }

  const fetchSearchData = async () => {
    setIsLoading(true)
    SearchApi.getSearchData(searchValue).then(res => {
      if (res.results) {
        setSearchResult(res.results);
      }
      setIsLoading(false)

    }).catch(err => {
      console.log(err)
      setIsLoading(false)
    })
  };


  const fetchOwner = async (data: ISearchDedicationInput) => {
    setIsLoading(true)
    const body = {
      identityAddress: {
        address: data.address.toLowerCase(),
        chain: SKEPTIC_CONNECT_CHAIN,
      },
      nfts: [
        {
          crypto_address: {
            address: artDropContract.toLowerCase(),
            chain: SKEPTIC_CONNECT_CHAIN,
          },
          network: SKEPTIC_CONNECT_NETWORK,
        },
      ],
    }

    const response: INftsInput = await fetch(`${SKEPTIC_CONNECT_SERVICES_BASE_URL}/identity/balances`, {
      method: 'post',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    }).then(res => res.json())
    if (response.nfts?.[0]?.tokens?.length) {
      tokenOrdinals(response.nfts[0].tokens)
    }
    else {
      setIsLoading(false)
    }
  }


  const tokenOrdinals = async (tokens: ITokenDetailInput[]) => {
    const query = (tokens?.map((t) => t.token_id) || []).join("-")
    SearchApi.getTokenOrdinals(query).then(res => {
      setTokens(res.results || [])
      setView("list")
      setIsLoading(false)
    }).catch(err => {
      console.log(err)
      setIsLoading(false)
    })
  }

  const singleTokenOrdinal = async (token_id: string) => {
    SearchApi.getTokenOrdinals(token_id).then(res => {
      if (res.results?.[0]) {
        getTokenInfo(Number(res.results[0]), true)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  const getTokenInfo = async (token: number, isRandom?: boolean) => {
    if (isRandom) {
      SearchApi.getTokenInfo(token).then(res => {
        if (res.results?.tokenInfo) {
          const tokenInfo = res.results.tokenInfo
          setRandomDedicationDetail({ tile: tokenInfo.tokenURIData.arteq.thekiss.token.data.tile[0].url, position: `x ${tokenInfo.tokenURIData.attributes.find((t: any) => t.trait_type === "X")?.value || "00"} y ${tokenInfo.tokenURIData.attributes.find((t: any) => t.trait_type === "Y")?.value || "00"}`, id: tokenInfo.tokenURIData.attributes.find((t: any) => t.trait_type === "Number")?.value || "" })
        }
      }).catch(err => {
        console.log(err)
      })

    } else {
      setDetailView("nft")
      setIsLoading(true)
      setSingleDedication(undefined)
      SearchApi.getTokenInfo(token).then(res => {
        if (res.results?.tokenInfo) {
          setTokenDetail(res.results.tokenInfo)
        }
        setIsLoading(false)
        getSingleDedication(token)
      }).catch(err => {
        console.log(err)
        setTokenDetail(undefined)
        setIsLoading(false)
      })

    }
  }

  const onBack = () => {
    setDetailView("dedication")
    setTokenDetail(undefined)
    setView("search")
    setSelectedToken(undefined)
    setSingleDedication(undefined)
  }

  const getSingleDedication = async (ordinal: string | number) => {
    SearchApi.getSingleDedication(ordinal).then((res: ISingleDedicationInput) => {
      if (res.results && res.ok) {
        setSingleDedication(res.results.dedication)
      }
    }).catch(err => {
      console.log(err)
    })
  }

  return {
    searchValue,
    handleChange,
    searchResult,
    tokenDetail,
    fetchOwner,
    tokens,
    view,
    setView,
    selectedToken,
    setSelectedToken,
    detailView,
    isLoading,
    randomDedication,
    randomDedicationDetail,
    singleDedication,
    onBack,
  };
}

export default useSearch;