import React from "react";
import SearchPage from "./views/search";

interface AppProps { }

const App: React.FC<AppProps> = () => {


  return <SearchPage />;
};

export default App;
