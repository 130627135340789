// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './styles/index.css';
// import App from './App';

// const root = ReactDOM.createRoot(
//   document.getElementById('root') as HTMLElement
// );
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import { IConfigInput } from './types';

const renderApp = (config: IConfigInput) => {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
};

const loadConfig = async () => {
  try {
    let configUrl = "/config.json";
    if (process.env.NODE_ENV === "development") {
      configUrl = "/config.dev.json";
    }

    const resp = await fetch(configUrl);
    const config: IConfigInput = await resp.json();
    window.BASE_URL = config.servicesURL;
    window.SKEPTIC_CONNECT_CHAIN = config.chain;
    window.SKEPTIC_CONNECT_NETWORK = config.network;
    window.artDropContract = config.artDropContract;
    window.SKEPTIC_CONNECT_SERVICES_BASE_URL = config.skepticServicesURL;

    renderApp(config); // Render the app after loading the config
  } catch (error) {
    console.error("Error loading configuration:", error);
  }
};

loadConfig();




