import GustavIcon from "../../../assets/svgs/gustav";
import { IDedicationDetail, IRandomDedication } from "../../../types";

interface RandomDedicationProps {
  data?: IRandomDedication;
  detail?: IDedicationDetail;
}
// x 098 y 103
//#09821
const RandomDedication: React.FC<RandomDedicationProps> = ({ data, detail }) => {
  return (<div className="default-dedication-container">
    <div className="default-icon">
      <GustavIcon />
    </div>
    <div className="default-dedication">
      {detail?.position ? <>
        <p className=" dedication-position">{detail.position}</p>
        <p className=" dedication-id">{detail?.id}</p>
        <div className="dedication-image-relative">
          <div className=" dedication-image-container">
            <img src={detail.tile} alt="" className=" dedication-image" loading="lazy" />
          </div>
        </div>

      </> : <>
        <div className="card is-loading" style={{ width: "100%", height: "100%", padding: 0 }}>
          <div style={{ width: "25%", height: 30, marginBottom: 8, marginTop: 8 }}></div>
          <div style={{ width: "25%", height: 30, marginBottom: 8 }}></div>
        </div>
        <div className="dedication-image-relative">
          <div className=" dedication-image-container" >
            <div className="card is-loading" style={{ width: "100%", height: "100%", padding: 0, }}>
              <div style={{ width: "100%", height: '100%', }}></div>
            </div>
          </div>
        </div>
      </>}


      <p className="dedication-text">Out of 10,000</p>
      <div className="dedication-info-container">
        <div className="dedication-info">
          <p className=""><span className="dedication-info-1">From: </span><span className="dedication-info-2">{data?.from_who || ""}</span></p>
          <p className="dedication-info-3">Dedication</p>
          <p className=""><span className="dedication-info-1">To: </span><span className="dedication-info-2">{data?.to_whom || ""}</span></p>
        </div>
        <div className="dedication-msg-container">
          <p className="dedication-msg ">{data?.message}</p>
        </div>
      </div>
    </div>
  </div>);
}

export default RandomDedication;