const SearchApi = {
	getRandomDedication: () =>
		fetch(window.BASE_URL + "/dedications/random/1", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response) => response.json()),
	getTokenInfo: (token: number) =>
		fetch(window.BASE_URL + "/tokens/info/" + token, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response) => response.json()),
	getSearchData: (q: string) =>
		fetch(window.BASE_URL + "/dedications/search/" + q, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response) => response.json()),
	getTokenOrdinals: (query: string) =>
		fetch(window.BASE_URL + "/tokens/ordinals/" + query, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response) => response.json()),
	getSingleDedication: (ordinal: string | number) =>
		fetch(window.BASE_URL + "/dedications/screen/" + ordinal, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
			},
		}).then((response) => response.json()),
};

export default SearchApi;
