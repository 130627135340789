import { useEffect, useState } from "react";
import GustavIcon from "../../../assets/svgs/gustav";
import ImageSkelton from "./imageSkelton";
import { ISingleDedication, ITokenInfoInput } from "../../../types";

interface ImageViewProps {
  tokenDetail?: ITokenInfoInput;
  isLoading: boolean;
  dedication?: ISingleDedication;
}

const ImageView: React.FC<ImageViewProps> = ({ tokenDetail, isLoading, dedication }) => {
  const [tokenImageLoaded, setTokenImageLoaded] = useState(false);

  useEffect(() => {
    if (isLoading || !tokenDetail) {
      setTokenImageLoaded(false)
    }
  }, [isLoading, tokenDetail])

  return (<div className="nft-image-container">
    <div className="nft-image-icon">
      <GustavIcon />
    </div>
    <div className="nft-image-box">
      <div className=" image-container">
        {tokenDetail && !isLoading && tokenImageLoaded ? null : <ImageSkelton />}
        {tokenDetail && !isLoading ? <img src={tokenDetail.tokenURIData.image} alt={tokenDetail.tokenURIData.name} className="img" onLoad={() => setTokenImageLoaded(true)} /> : null}
      </div>
    </div>
    {dedication?.is_public ? <div className="dedication-info-container">
      <div className="dedication-info">
        <p className=""><span className="dedication-info-1">From: </span><span className="dedication-info-2">{dedication.from || ""}</span></p>
        <p className="dedication-info-3">Dedication</p>
        <p className=""><span className="dedication-info-1">To: </span><span className="dedication-info-2">{dedication.to || ""}</span></p>
      </div>
      <div className="dedication-msg-container">
        <p className="dedication-msg ">{dedication.message}</p>
      </div>
    </div> : null}
  </div>);
}

export default ImageView;