import GustavIcon from "../../../assets/svgs/gustav";

interface RandomDedicationSkeltonProps {

}

const RandomDedicationSkelton: React.FC<RandomDedicationSkeltonProps> = () => {
  return (
    <div className="default-dedication-container">
      <div className="default-icon">
        <GustavIcon />
      </div>
      <div className="card is-loading" style={{ width: "100%", }}>
        <div style={{ width: "25%", height: 30, marginBottom: 8 }}></div>
        <div style={{ width: "25%", height: 30, marginBottom: 8 }}></div>
        <div className="dedication-image-relative" style={{ marginBottom: 8 }}>
          <div style={{ width: "100%", height: "100%", position: 'absolute', top: 0, right: 0 }}></div>
        </div>

        <div style={{ width: "50%", height: 30, marginBottom: 8 }}></div>
        <div style={{ width: "50%", height: 80, marginBottom: 8 }}></div>
      </div>
    </div>

  );
}

export default RandomDedicationSkelton;