import Search from "../components/templates/search";

interface SearchPageProps {

}

const SearchPage: React.FC<SearchPageProps> = () => {
  return (
    <Search />
  );
}

export default SearchPage;