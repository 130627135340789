import Loader from "../../../assets/svgs/loader";
import { ITokenInfoInput } from "../../../types";

interface NftListProps {
  tokens: number[];
  selectedToken?: number;
  onSelect: (data: number) => void;
  isLoading: boolean;
  onBack: () => void;
  tokenInfo?: ITokenInfoInput
}

const NftList: React.FC<NftListProps> = ({ tokens, selectedToken, onSelect, isLoading, onBack, tokenInfo }) => {

  return (<div className=" nft-list-container">
    <div className="nft-list-content">
      <div className="nft-btn-container">
        <button onClick={onBack} className="nft-home-btn">Home</button>
        <button className="nft-back-btn" onClick={onBack}>Back</button>
      </div>
      <h1 className=" nft-title">The Owners of "The Kiss" NFT</h1>
      <div className="nfts-container">
        {tokens.length ? tokens.map((nft, idx) => (
          <div className={`nft-detail-container ${selectedToken === nft ? "active" : ""}`} key={idx} onClick={() => onSelect(nft)}>
            <p className="nft-number">{`${(idx + 1)}.`}</p>
            <p className="nft-id">{`#${nft}`}</p>
            {selectedToken === nft && isLoading ? <Loader stroke="#fff" /> : <button className="nft-btn">View</button>}
          </div>
        )) : null}
      </div>
      {tokenInfo && !isLoading ?
        <div className="" style={{ display: "flex", width: "100%", paddingTop: 40 }}>
          <div className="" style={{ width: "max-content", paddingRight: "40px" }}>
            <p style={{ marginBottom: 8, fontWeight: "bold", fontSize: 17, color: "#000" }}>Owner</p>
            <p style={{ marginBottom: 8, fontWeight: "bold", fontSize: 17, color: "#000" }}>Token ID</p>
            <p style={{ marginBottom: 8, fontWeight: "bold", fontSize: 17, color: "#000" }}>Coordinates</p>
            <p style={{ marginBottom: 8, fontWeight: "bold", fontSize: 17, color: "#000" }}>Owner address</p>
            <p style={{ marginBottom: 8, fontWeight: "bold", fontSize: 17, color: "#000" }}>Claimed</p>
            <p style={{ marginBottom: 8, fontWeight: "bold", fontSize: 17, color: "#000" }}>Contract Address</p>
          </div>
          <div className="">
            <p style={{ marginBottom: 8, marginTop: 2, fontWeight: 300, fontSize: 18, color: "#000" }}>Owner</p>
            <p style={{ marginBottom: 8, marginTop: 2, fontWeight: 300, fontSize: 18, color: "#000" }}>{tokenInfo.tokenId}</p>
            <p style={{ marginBottom: 8, marginTop: 2, fontWeight: 300, fontSize: 18, color: "#000" }}>{`${tokenInfo.tokenURIData.attributes[1].trait_type}${tokenInfo?.tokenURIData.attributes[1].value}, ${tokenInfo?.tokenURIData.attributes[2].trait_type}${tokenInfo?.tokenURIData.attributes[2].value}`}</p>
            <p style={{ marginBottom: 8, marginTop: 2, fontWeight: 300, fontSize: 18, color: "#000" }}><a
              href={`https://etherscan.io/address/${tokenInfo.owner
                }`}
              rel="noreferrer"
              target="_blank"
            >
              {tokenInfo.owner.slice(0, 16)}...
            </a></p>
            <p style={{ marginBottom: 8, marginTop: 2, fontWeight: 300, fontSize: 18, color: "#000" }}>Feb 14 2022</p>
            <p style={{ marginBottom: 8, marginTop: 2, fontWeight: 300, fontSize: 18, color: "#000" }}>	<a
              href={`https://etherscan.io/address/${window.artDropContract
                }`}
              rel="noreferrer"
              target="_blank"
            >
              {window.artDropContract.slice(0, 16)}
              ...
            </a></p>
          </div>
        </div> : null}
    </div>
  </div>);
}

export default NftList;