import type { SVGProps } from "react";

interface Props extends SVGProps<SVGSVGElement> { }

const ArteqIcon: React.FC<Props> = (props) => {
  return (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="87.286" height="28.674">
      <defs><clipPath id="a"><path data-name="Rechteck 7196" fill="none" d="M0 0h87.286v28.675H0z" /></clipPath></defs>
      <g data-name="Gruppe 285">
        <g data-name="Gruppe 284" clipPath="url(#a)">
          <path data-name="Pfad 1246" d="M86.366 25.454a8.361 8.361 0 0 1-.669-.486 5.586 5.586 0 0 1-2.214-2.9l-.043-.182.13-.13a12.308 12.308 0 0 0 3.716-8.994 12.3 12.3 0 0 0-3.742-9.02 12.711 12.711 0 0 0-18.014 0 12.3 12.3 0 0 0-3.733 9.02 12.258 12.258 0 0 0 3.733 8.994 12.31 12.31 0 0 0 9.02 3.742 12.637 12.637 0 0 0 5.165-1.1l.243-.1.156.217a17.028 17.028 0 0 0 4.184 4.167l2.787-2.787a1.156 1.156 0 0 1-.148-.078 7.979 7.979 0 0 1-.573-.356m-5.817-6.693a8.51 8.51 0 0 1-12.015 0 8.2 8.2 0 0 1-2.483-6 8.245 8.245 0 0 1 2.481-6.031 8.51 8.51 0 0 1 12.015 0 8.246 8.246 0 0 1 2.483 6.025 8.2 8.2 0 0 1-2.483 6" fill="#fff" />
          <path data-name="Pfad 1247" d="M86.366 25.454a8.361 8.361 0 0 1-.669-.486 5.586 5.586 0 0 1-2.214-2.9l-.043-.182.13-.13a12.308 12.308 0 0 0 3.716-8.994 12.3 12.3 0 0 0-3.742-9.02 12.711 12.711 0 0 0-18.014 0 12.3 12.3 0 0 0-3.733 9.02 12.258 12.258 0 0 0 3.733 8.994 12.31 12.31 0 0 0 9.02 3.742 12.637 12.637 0 0 0 5.165-1.1l.243-.1.156.217a17.028 17.028 0 0 0 4.184 4.167l2.787-2.787a1.156 1.156 0 0 1-.148-.078 7.979 7.979 0 0 1-.573-.356m-5.817-6.693a8.51 8.51 0 0 1-12.015 0 8.2 8.2 0 0 1-2.483-6 8.245 8.245 0 0 1 2.481-6.031 8.51 8.51 0 0 1 12.015 0 8.246 8.246 0 0 1 2.483 6.025 8.2 8.2 0 0 1-2.483 6" fill="#fff" />
          <path data-name="Pfad 1248" d="M13.288 7.511h3.4v16.043h-3.416L13 21.674a6.324 6.324 0 0 1-4.929 2.266A8.231 8.231 0 0 1 0 15.548a8.24 8.24 0 0 1 8.068-8.389 6.326 6.326 0 0 1 4.975 2.3Zm-.276 8.052a4.572 4.572 0 1 0-9.139 0A4.479 4.479 0 0 0 8.4 20.232a4.541 4.541 0 0 0 4.608-4.669" fill="#fff" />
          <path data-name="Pfad 1249" d="m32.5 8.43-1.73 3.184a4.266 4.266 0 0 0-2.449-.842c-1.761.015-3.429 1.7-3.506 4.378v8.4h-3.9V7.511h3.353l.275 2.159a4.772 4.772 0 0 1 4.256-2.5 6.569 6.569 0 0 1 3.7 1.255" fill="#fff" />
          <path data-name="Pfad 1250" d="M35.317 23.554V11.093h-2.3v-3.6h2.281V3.669l3.934-.934v4.761h3.536v3.6h-3.533v12.458Z" fill="#fff" />
          <path data-name="Pfad 1251" d="M47.778 16.849a4.574 4.574 0 0 0 4.639 3.674 5.492 5.492 0 0 0 3.812-1.47l1.868 2.709a8.536 8.536 0 0 1-5.909 2.22 8.211 8.211 0 0 1-8.266-8.389c0-4.654 3.276-8.419 7.853-8.419 5.036 0 7.914 3.49 7.914 8.312a8.749 8.749 0 0 1-.092 1.362Zm.031-2.97h8.113a3.881 3.881 0 0 0-4-3.322 4.106 4.106 0 0 0-4.118 3.322m6.72-8.511h-2.878l-3.184-4.24h3.981Z" fill="#fff" />
          <path data-name="Pfad 1252" d="M86.366 25.454a8.361 8.361 0 0 1-.669-.486 5.586 5.586 0 0 1-2.214-2.9l-.043-.182.13-.13a12.308 12.308 0 0 0 3.716-8.994 12.3 12.3 0 0 0-3.742-9.02 12.711 12.711 0 0 0-18.014 0 12.3 12.3 0 0 0-3.733 9.02 12.258 12.258 0 0 0 3.733 8.994 12.31 12.31 0 0 0 9.02 3.742 12.637 12.637 0 0 0 5.165-1.1l.243-.1.156.217a17.028 17.028 0 0 0 4.184 4.167l2.787-2.787a1.156 1.156 0 0 1-.148-.078 7.979 7.979 0 0 1-.573-.356m-5.817-6.693a8.51 8.51 0 0 1-12.015 0 8.2 8.2 0 0 1-2.483-6 8.245 8.245 0 0 1 2.481-6.031 8.51 8.51 0 0 1 12.015 0 8.246 8.246 0 0 1 2.483 6.025 8.2 8.2 0 0 1-2.483 6" fill="#231f20" />
          <path data-name="Pfad 1253" d="M86.366 25.454a8.361 8.361 0 0 1-.669-.486 5.586 5.586 0 0 1-2.214-2.9l-.043-.182.13-.13a12.308 12.308 0 0 0 3.716-8.994 12.3 12.3 0 0 0-3.742-9.02 12.711 12.711 0 0 0-18.014 0 12.3 12.3 0 0 0-3.733 9.02 12.258 12.258 0 0 0 3.733 8.994 12.31 12.31 0 0 0 9.02 3.742 12.637 12.637 0 0 0 5.165-1.1l.243-.1.156.217a17.028 17.028 0 0 0 4.184 4.167l2.787-2.787a1.156 1.156 0 0 1-.148-.078 7.979 7.979 0 0 1-.573-.356m-5.817-6.693a8.51 8.51 0 0 1-12.015 0 8.2 8.2 0 0 1-2.483-6 8.245 8.245 0 0 1 2.481-6.031 8.51 8.51 0 0 1 12.015 0 8.246 8.246 0 0 1 2.483 6.025 8.2 8.2 0 0 1-2.483 6" fill="#231f20" />
          <path data-name="Pfad 1254" d="M13.288 7.511h3.4v16.043h-3.416L13 21.674a6.324 6.324 0 0 1-4.929 2.266A8.231 8.231 0 0 1 0 15.548a8.24 8.24 0 0 1 8.068-8.389 6.326 6.326 0 0 1 4.975 2.3Zm-.276 8.052a4.572 4.572 0 1 0-9.139 0A4.479 4.479 0 0 0 8.4 20.232a4.541 4.541 0 0 0 4.608-4.669" fill="#231f20" />
          <path data-name="Pfad 1255" d="m32.5 8.43-1.73 3.184a4.266 4.266 0 0 0-2.449-.842c-1.761.015-3.429 1.7-3.506 4.378v8.4h-3.9V7.511h3.353l.275 2.159a4.772 4.772 0 0 1 4.256-2.5 6.569 6.569 0 0 1 3.7 1.255" fill="#231f20" />
          <path data-name="Pfad 1256" d="M35.317 23.554V11.093h-2.3v-3.6h2.281V3.669l3.934-.934v4.761h3.536v3.6h-3.533v12.458Z" fill="#231f20" />
          <path data-name="Pfad 1257" d="M47.778 16.849a4.574 4.574 0 0 0 4.639 3.674 5.492 5.492 0 0 0 3.812-1.47l1.868 2.709a8.536 8.536 0 0 1-5.909 2.22 8.211 8.211 0 0 1-8.266-8.389c0-4.654 3.276-8.419 7.853-8.419 5.036 0 7.914 3.49 7.914 8.312a8.749 8.749 0 0 1-.092 1.362Zm.031-2.97h8.113a3.881 3.881 0 0 0-4-3.322 4.106 4.106 0 0 0-4.118 3.322m6.72-8.511h-2.878l-3.184-4.24h3.981Z" fill="#231f20" />
        </g>
      </g>
    </svg>
  );
};

export default ArteqIcon;
