interface ImageSkeltonProps {

}

const ImageSkelton: React.FC<ImageSkeltonProps> = () => {
  return (<div className="nft-image-box" style={{ paddingTop: 100 }}>
    <div style={{ width: "100%", display: "flex", alignItems: "flex-end" }}>
      <div style={{ width: "65%", paddingTop: "65%", position: "relative" }}>
        <div style={{ width: "100%", height: "100%", top: 0, left: 0, position: "absolute" }}>
          <div className="card is-loading" style={{ width: "100%", height: "100%", padding: 0 }}>
            <div style={{ width: "100%", height: "100%" }}></div>
          </div>
        </div>
      </div>
      <div style={{ width: "35%", paddingLeft: 16, display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
        <div className="card is-loading" style={{ padding: 0 }}>
          <div style={{ width: "100%", height: 30, marginBottom: 8 }}></div>
          <div style={{ width: "100%", height: 30, marginBottom: 8 }}></div>
          <div style={{ width: "100%", height: 30, marginBottom: 8 }}></div>
          <div style={{ width: "100%" }}>
            <div style={{ width: "100%", paddingTop: "100%", position: "relative" }}>
              <div style={{ width: "100%", height: "100%", top: 0, left: 0, position: "absolute" }}>
                <div style={{ width: "100%", height: "100%" }}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="card is-loading" style={{ width: "100%", paddingTop: 16 }}>
      <div style={{ width: "100%", height: 40, marginBottom: 8 }}></div>
      <div style={{ width: "100%", height: 100, marginBottom: 8 }}></div>
    </div>
  </div>);
}

export default ImageSkelton;